const colors = {
	base: {
		red: '#f76161',
		orange: '#FF7F41',
		yellow: '#FFC72C',
		green: '#006F62',
		green2: '#005F50',
		magenta: '#C6579A',
		blue: '#3C4092',
		blue2: '#004178',
		pink: '#ECBAA8',
		paleYellow: '#F5E1A4',
		purple: '#6A2A5B',
		purple2: '#410F4B',
		teal: '#00737D',
		turquoise: '#A0DAB3',
		cyan: '#3EB1C8',
		beige: '#ECE6D8',
	},
	highTint: {
		red: '#EB8F7F',
		orange: '#FF9F70',
		orange2: '#FB6600',
		yellow: '#FFD561',
		green: '#409389',
		green2: '#E6FAEB',
		magenta: '#D481B3',
		blue: '#6D70AD',
		blue2: '#D7F0FF',
		pink: '#F1CBBE',
		pink2: '#F76161',
		paleYellow: '#F7E8BB',
		paleOrange: '#FFAA2D',
		purple: '#8F5F84',
		teal: '#DCF5F5',
		turquoise: '#B8E3C6',
		cyan: '#6EC4D6',
		beige: '#F1ECE2',
		apple:'#e9f9f2',
		sky:'#effaff',
		newYellow: '#fffbf0',
		newOrange: '#fff4eb',
		newPink: '#fff1f1',
		electric: '#eeefff',
	},
	lowTint: {
		red: '#F7D2CC',
		orange: '#FFD9C6',
		orange2: '#FFDCB4',
		orange3: '#FFF5E9',
		yellow: '#FFEEC0',
		yellow2: '#FCF2BD',
		green: '#B2D4D0',
		green2: '#F8FEF9',
		magenta: '#EECDE1',
		blue: '#C4C6DE',
		blue2: '#F3FBFF',
		pink: '#F9EAE5',
		pink2: '#FEF5FB',
		paleYellow: '#FCF6E4',
		paleRed: '#FFE6DC',
		purple: '#D2BFCE',
		teal: '#F5FCFC',
		turquoise: '#E2F4E8',
		cyan: '#C5E8EE',
		beige: '#F9F7F3',
		redTint: '#FFF8F5',
	},
	monochrome: {
		black: '#000000',
		grey75: '#404040',
		grey30: '#B3B3B3',
		grey15: '#D9D9D9',
		grey5: '#F2F2F2',
		white: '#FFFFFF',
		noir:'#161617',
		charcoal:'#2b2b2c',
		dust:'#dbdedb',
	},
	messaging: {
		errorForeground: '#C21515',
		errorBackground: '#FCF3F3',
		validForeground: '#217E15',
		validBackground: '#F4F9F3',
	},

	new: {
		noir:'#161617',
		charcoal:'#2b2b2c',
		dust:'#E4E4E4',
		sky:'#5ac8fa',
		yellow:'#ffd966',
		orange:'#fa8f38',
		pink:'#ff7373',
		electric:'#5463ff',
		apple:'#27bf78',
	},
};

export default colors;
