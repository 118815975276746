import { z } from 'zod';
import path from 'path';

const nexudusEnvSchema = z.enum(['DEV', 'PROD']);
const urlOrPathSchema = z
	.string()
	.url()
	.or(z.string().regex(/^\/[a-zA-Z0-9_\-/]+(\/[a-zA-Z0-9_\-/]+)*$/)); // Must start with a slash

export const environmentVariables = {
	// WE have to explicitly mention each variable here so next knows to include them in the client bundle

	// Client and server-side variables
	NEXT_PUBLIC_NEXUDUS_ENV: process.env.NEXT_PUBLIC_NEXUDUS_ENV,
	NEXT_PUBLIC_EXPLORE_URL: process.env.NEXT_PUBLIC_EXPLORE_URL,
	NEXT_PUBLIC_GOOGLE_MAP_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
	NEXT_PUBLIC_MEDIA_SERVER: process.env.NEXT_PUBLIC_MEDIA_SERVER,
	NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
	NEXT_PUBLIC_GTAG: process.env.NEXT_PUBLIC_GTAG,
	NEXT_PUBLIC_GA4: process.env.NEXT_PUBLIC_GA4,
	NEXT_PUBLIC_CONTENT_API_URL: process.env.NEXT_PUBLIC_CONTENT_API_URL,
	NEXT_PUBLIC_CONTENT_GLOBAL_URL: process.env.NEXT_PUBLIC_CONTENT_GLOBAL_URL,
	NEXT_PUBLIC_APPLICATION_INSIGHTS_CONNECTION_STRING:
		process.env.NEXT_PUBLIC_APPLICATION_INSIGHTS_CONNECTION_STRING,
	APPLICATIONINSIGHTS_CONNECTION_STRING: process.env.APPLICATIONINSIGHTS_CONNECTION_STRING,
	// Server-side only variables
	CONTENT_API_KEY: process.env.CONTENT_API_KEY,
	NEXUDUS_REDIRECT_TO_URL: process.env.NEXUDUS_REDIRECT_TO_URL,
	CACHE_RESET_TOKEN: process.env.CACHE_RESET_TOKEN,
	SENDGRID_CUSTOMER_RELATIONS_EMAIL: process.env.SENDGRID_CUSTOMER_RELATIONS_EMAIL,
	SENDGRID_EMAIL: process.env.SENDGRID_EMAIL,
	SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
	PUBLIC_DIR: process.env.PUBLIC_DIR,
} as const;

/**
 * This contains all the environment variables that can be used in client side code. Since we use the pages router, and
 * nearly every component can run both client and server
 */
const clientConfigObjectSchema = z.object({
	NEXT_PUBLIC_NEXUDUS_ENV: nexudusEnvSchema,
	NEXT_PUBLIC_EXPLORE_URL: urlOrPathSchema,
	NEXT_PUBLIC_GOOGLE_MAP_API_KEY: z.string(),
	NEXT_PUBLIC_MEDIA_SERVER: urlOrPathSchema,
	NEXT_PUBLIC_SITE_URL: urlOrPathSchema,
	NEXT_PUBLIC_GTAG: z.string(),
	NEXT_PUBLIC_GA4: z.string(),
	NEXT_PUBLIC_CONTENT_API_URL: urlOrPathSchema,
	NEXT_PUBLIC_CONTENT_GLOBAL_URL: urlOrPathSchema,
	NEXT_PUBLIC_APPLICATION_INSIGHTS_CONNECTION_STRING: z.string(),
});

const clientConfigTransformer = (data: z.TypeOf<typeof clientConfigObjectSchema>) => ({
	isProd: data.NEXT_PUBLIC_NEXUDUS_ENV === 'PROD',
	isDev: data.NEXT_PUBLIC_NEXUDUS_ENV === 'DEV',
	exploreUrl: data.NEXT_PUBLIC_EXPLORE_URL,
	googleMapApiKey: data.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
	mediaServer: data.NEXT_PUBLIC_MEDIA_SERVER,
	siteUrl: data.NEXT_PUBLIC_SITE_URL,
	gtag: data.NEXT_PUBLIC_GTAG,
	ga4: data.NEXT_PUBLIC_GA4,
	contentApiUrl: data.NEXT_PUBLIC_CONTENT_API_URL,
	contentGlobalUrl: data.NEXT_PUBLIC_CONTENT_GLOBAL_URL,
	applicationInsightsConnectionString: data.NEXT_PUBLIC_APPLICATION_INSIGHTS_CONNECTION_STRING,
});

const clientConfigSchema = clientConfigObjectSchema.transform(clientConfigTransformer);

const serverConfigObjectSchema = z.intersection(
	clientConfigObjectSchema,
	z.object({
		CONTENT_API_KEY: z.string(),
		NEXUDUS_REDIRECT_TO_URL: urlOrPathSchema,
		CACHE_RESET_TOKEN: z.string(),
		SENDGRID_CUSTOMER_RELATIONS_EMAIL: z.string().email(),
		SENDGRID_EMAIL: z.string().email(),
		SENDGRID_API_KEY: z.string(),
		APPLICATIONINSIGHTS_CONNECTION_STRING: z.string().optional(),
		PUBLIC_DIR: z.string(),
	})
);

const serverConfigTransformer = (data: z.TypeOf<typeof serverConfigObjectSchema>) => ({
	...clientConfigTransformer(data),
	portalRoot: data.NEXUDUS_REDIRECT_TO_URL,
	cacheResetToken: data.CACHE_RESET_TOKEN,
	contentApiKey: data.CONTENT_API_KEY,
	sendgridCustomerRelationsEmail: data.SENDGRID_CUSTOMER_RELATIONS_EMAIL,
	sendgridEmail: data.SENDGRID_EMAIL,
	sendgridApiKey: data.SENDGRID_API_KEY,
	applicationInsightsConnectionString:
		data.APPLICATIONINSIGHTS_CONNECTION_STRING ||
		data.NEXT_PUBLIC_APPLICATION_INSIGHTS_CONNECTION_STRING,
	publicDir: path.isAbsolute(data.PUBLIC_DIR)
		? data.PUBLIC_DIR
		: path.join(process.cwd(), data.PUBLIC_DIR),
});

const configSchema = serverConfigObjectSchema.transform(serverConfigTransformer);

export const clientConfig = () => clientConfigSchema.parse(environmentVariables);

export const serverConfig = () => configSchema.parse(environmentVariables);

export default {
	serverConfig,
	clientConfig,
};
