import React from 'react';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import App from 'next/app';
import themes from '@/styles/settings/themes';

import '@/components/molecules/MapWithCards/MapWithCards.css';
import '@bruntwood/bruntwood-explore-externals/index.css';
import NextThemeProvider from '@/components/providers/NextThemeProvider';
import { serverConfig } from '@/env/config';
import { AppInsightsProvider } from '@/AppInsightsProvider';

if (typeof window !== 'undefined') {
  window.document.version = {
    package: process.env.NEXT_PUBLIC_PACKAGE_VERSION,
    release: process.env.NEXT_PUBLIC_RELEASE_ID
  };
}

type AppOwnProps = { applicationInsightsConnectionString?: string };


const CustomApp = ({ Component, pageProps, applicationInsightsConnectionString }: AppProps<{data: any}> & AppOwnProps) => {
	const theme = pageProps.data ? pageProps.data.theme : themes.blue1;

	return (
		<AppInsightsProvider appInsightsConnectionString={applicationInsightsConnectionString}>
			<NextThemeProvider theme={theme}>
				<Component {...pageProps} />
			</NextThemeProvider>
		</AppInsightsProvider>
	);
};


CustomApp.getInitialProps = async (
	context: AppContext
): Promise<AppOwnProps & AppInitialProps> => {
	const ctx = await App.getInitialProps(context);
	const config = serverConfig();
	return { ...ctx, applicationInsightsConnectionString: config.applicationInsightsConnectionString };
};
export default CustomApp;
