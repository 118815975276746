import colors from './colors';

export type ThemesType = typeof themes;
// {
// 	blue1: {
// 		[key: string]: string;
// 	};
// 	blue2: {
// 		[key: string]: string;
// 	};
// 	green1: {
// 		[key: string]: string;
// 	};
// 	green2: {
// 		[key: string]: string;
// 	};
// 	group1: {
// 		[key: string]: string;
// 	};
// 	group2: {
// 		[key: string]: string;
// 	};
// 	group3: {
// 		[key: string]: string;
// 	};
// 	group4: {
// 		[key: string]: string;
// 	};
// 	group5: {
// 		[key: string]: string;
// 	};
// 	group6: {
// 		[key: string]: string;
// 	};
// 	purple1: {
// 		[key: string]: string;
// 	};
// 	purple2: {
// 		[key: string]: string;
// 	};
// 	scitech1: {
// 		[key: string]: string;
// 	};
// 	scitech2: {
// 		[key: string]: string;
// 	};
// 	scitech3: {
// 		[key: string]: string;
// 	};
// 	scitech4: {
// 		[key: string]: string;
// 	};
// 	scitech5: {
// 		[key: string]: string;
// 	};
// 	scitech6: {
// 		[key: string]: string;
// 	};
// 	works1: {
// 		[key: string]: string;
// 	};
// 	works2: {
// 		[key: string]: string;
// 	};
// 	works3: {
// 		[key: string]: string;
// 	};
// 	works4: {
// 		[key: string]: string;
// 	};
// 	works5: {
// 		[key: string]: string;
// 	};
// 	works6: {
// 		[key: string]: string;
// 	};
// };

const themes = {
	blue1: {
		primaryForeground: colors.new.sky,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.new.sky,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.sky,
		...colors.monochrome,
		...colors.messaging,
	},
	blue2: {
		primaryForeground: colors.new.electric,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.new.electric,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.electric,
		...colors.monochrome,
		...colors.messaging,
	},
	green1: {
		primaryForeground: colors.new.apple,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.new.apple,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.apple,
		...colors.monochrome,
		...colors.messaging,
	},
	green2: {
		primaryForeground: colors.new.apple,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.new.apple,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.apple,
		...colors.monochrome,
		...colors.messaging,
	},
	group1: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	group2: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	group3: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	group4: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	group5: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	group6: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	purple1: {
		primaryForeground: colors.new.electric,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.new.electric,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.electric,
		...colors.monochrome,
		...colors.messaging,
	},
	purple2: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech1: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech2: {
		primaryForeground: colors.new.apple,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.new.apple,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.apple,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech3: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech4: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech5: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	scitech6: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	works1: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	works2: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	works3: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	works4: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	works5: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	works6: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	},
	sustainability: {
		primaryForeground: colors.base.green2,
		primaryBackground: colors.lowTint.turquoise,
		primaryAccent: colors.highTint.green,
		rippleBase: colors.base.red,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.base.blue,
		secondaryBackground: colors.lowTint.turquoise,
		secondaryAccent: colors.base.turquoise,
		...colors.monochrome,
		...colors.messaging,
	},
	apple: {
		primaryForeground: colors.new.apple,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.new.apple,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.apple,
		...colors.monochrome,
		...colors.messaging,
	},
	sky: {
		primaryForeground: colors.new.sky,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.new.sky,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.sky,
		...colors.monochrome,
		...colors.messaging,
	},
	yellow: {
		primaryForeground: colors.new.yellow,
		headerBackground: colors.new.noir,
		primaryBackground: colors.highTint.newYellow,
		primaryAccent: colors.new.yellow,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.yellow,
		...colors.monochrome,
		...colors.messaging,
	},
	orange: {
		primaryForeground: colors.new.orange,
		headerBackground: colors.new.noir,
		primaryBackground: colors.highTint.newOrange,
		primaryAccent: colors.new.orange,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.orange,
		...colors.monochrome,
		...colors.messaging,
	},
	pink: {
		primaryForeground: colors.new.pink,
		headerBackground: colors.new.noir,
		primaryBackground: colors.highTint.newPink,
		primaryAccent: colors.new.pink,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.pink,
		...colors.monochrome,
		...colors.messaging,
	},
	electric: {
		primaryForeground: colors.new.electric,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.new.electric,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.new.electric,
		...colors.monochrome,
		...colors.messaging,
	},
		newgroup: {
		primaryForeground: colors.base.red,
		headerBackground: colors.new.noir,
		primaryBackground: colors.monochrome.white,
		primaryAccent: colors.base.red,
		rippleBase: colors.new.charcoal,
		rippleTint: colors.highTint.red,
		secondaryForeground: colors.new.charcoal,
		secondaryBackground: colors.monochrome.white,
		secondaryAccent: colors.base.red,
		...colors.monochrome,
		...colors.messaging,
	}


	
};

export default themes;
